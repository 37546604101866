<template lang="pug">
  v-container
    v-row
      v-col(cols="12" lg="3")
        v-toolbar(
          color="primary"
          dense
          elevation="0"
          dark
        )
          span Create
        create-herica-client
      v-col(cols="12" lg="9")
        v-toolbar(
          color="primary"
          dense
          elevation="0"
          dark
        )
          span List
        table-herica-client
</template>
<script>
export default {
  name: 'HericaClients',
  components: {
    createHericaClient: () => import('./Create'),
    tableHericaClient: () => import('./Table'),
  },
}
</script>